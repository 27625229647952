import type {SVGAttributes} from 'react';

const Mastercard = (props: SVGAttributes<SVGElement>) => (
  <svg width="58" height="37" viewBox="0 0 58 37" fill="none" {...props}>
    <path
      d="M55 0H3C1.34315 0 0 1.34315 0 3V34C0 35.6569 1.34315 37 3 37H55C56.6569 37 58 35.6569 58 34V3C58 1.34315 56.6569 0 55 0Z"
      fill="#0F1822"
    />
    <path d="M35.774 6.6853H22.6816V30.214H35.774V6.6853Z" fill="#F26522" />
    <path
      d="M23.5456 18.4495C23.5456 13.6763 25.7809 9.42462 29.2609 6.68516C26.7161 4.68272 23.5038 3.48682 20.0135 3.48682C11.7499 3.48682 5.05078 10.186 5.05078 18.4495C5.05078 26.7131 11.7499 33.4122 20.0135 33.4122C23.5038 33.4122 26.7161 32.2163 29.2609 30.2139C25.7809 27.4709 23.5456 23.2227 23.5456 18.4495Z"
      fill="#E52423"
    />
    <path
      d="M52.0431 27.718V27.2348H52.2378V27.1375H51.7441V27.2348H51.9388V27.718H52.0431ZM53.0026 27.718V27.1375H52.8497L52.6758 27.5372L52.502 27.1375H52.349V27.718H52.4568V27.28L52.6202 27.6589H52.7315L52.8948 27.28V27.718H53.0026Z"
      fill="#F99F1C"
    />
    <path
      d="M38.5091 3.48706C35.0187 3.48706 31.8065 4.68296 29.2617 6.68541C32.7417 9.42486 34.977 13.6766 34.977 18.4498C34.977 23.2229 32.7417 27.4712 29.2617 30.2106C31.8065 32.2131 35.0187 33.409 38.5091 33.409C46.7727 33.409 53.4718 26.7098 53.4718 18.4463C53.4683 10.1862 46.7727 3.48706 38.5091 3.48706Z"
      fill="#F99F1C"
    />
  </svg>
);

export default Mastercard;
