import {twMerge} from '@/stylesheets/twMerge';
import Col, {Span, Start} from '@/components/brochureV2/layout/Grid/Col';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Block from '@/components/brochureV2/primitive/Block/Block';
import Button from '@/components/brochureV2/primitive/Button/Button';
import {
  ButtonTheme,
  ButtonVariant,
} from '@/components/brochureV2/primitive/Button/types';
import Link from '@/components/brochureV2/primitive/Link/Link';
import Image from '@/components/shared/Image/Image';
import {AlignmentEnum, ButtonSizeEnum} from '@/enums';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import type {BlockComponent, ImageComponent, LinkComponent} from '@/types';

import {Heading} from '../shared';

interface Outcomes02Props {
  alignment?: AlignmentEnum;
  blocks?: BlockComponent[];
  heading?: string;
  image?: ImageComponent;
  kicker?: string;
  link?: LinkComponent;
  subhead?: string;
  buttonTheme?: ButtonTheme;
  gridColOneClass?: string;
  gridColTwoClass?: string;
  customSpacer?: boolean;
  customImageClass?: string;
}

/**
 * @deprecated
 */
export default function Outcomes02PreOrder({
  alignment,
  kicker,
  heading,
  subhead,
  image,
  blocks,
  link,
  buttonTheme,
  gridColOneClass,
  gridColTwoClass,
  customSpacer,
  customImageClass,
}: Outcomes02Props) {
  const currentAlignment = alignment || AlignmentEnum.Right;
  const isRight = currentAlignment === AlignmentEnum.Right;
  const currentButtonTheme = buttonTheme || ButtonTheme.Light;
  const isButton = link?.name === 'button';
  const isXSmall = useMediaQuery('(max-width: 640px)');
  const headingVals = {kicker, heading, subhead};
  const Content = blocks?.map((block, idx) => {
    const key = `${block.heading}-${idx}`;
    const isNotLastBlock = idx !== blocks.length - 1;
    return (
      <div
        className="gap-x-gutter grid grid-cols-4 gap-y-0 sm:grid-cols-8 md:grid-cols-7"
        key={key}
      >
        {block.icon?.src && (
          <Col
            span={{
              default: Span.Four,
              sm: Span.One,
            }}
            start={{
              sm: Start.Two,
              md: Start.One,
            }}
          >
            <div className="h-12 w-12 md:h-16 md:w-16 icon-wrapper">
              <Image
                alt={block.icon.alt}
                src={block.icon.src}
                width="100%"
                loading="lazy"
              />
            </div>
            {isXSmall && <Spacer size="md" />}
          </Col>
        )}
        <Col
          span={{
            default: Span.Four,
            sm: Span.Four,
            md: Span.Six,
          }}
          start={{
            sm: Start.Three,
            md: Start.Two,
          }}
        >
          <Block
            key={`${block.heading}-block`}
            {...block}
            linkTheme={currentButtonTheme}
            size="small"
          />
          {!customSpacer && isNotLastBlock && (
            <Spacer size={isXSmall ? '3xl' : 'xl'} />
          )}
        </Col>
      </div>
    );
  });

  return (
    <>
      <div className="container">
        <Heading {...headingVals} />
        <Spacer size="3xl" />
      </div>
      <Grid>
        {image ? (
          <>
            <Col
              start={{
                default: Start.One,
                sm: Start.Two,
                md: isRight ? Start.One : Start.Seven,
              }}
              span={{
                default: Span.Four,
                sm: Span.Five,
                md: isRight ? Span.Four : Span.Seven,
              }}
              className={twMerge('row-span-full', gridColOneClass)}
            >
              {image?.srcSet && (
                <Image
                  className={twMerge('mb-3xl md:mb-0', customImageClass)}
                  sizes={image.sizes}
                  srcSet={image.srcSet}
                  alt={image.alt}
                  loading="lazy"
                />
              )}
            </Col>
            <Col
              start={{
                default: Start.One,
                md: isRight ? Start.Six : Start.One,
              }}
              span={{
                default: Span.Four,
                sm: Span.Eight,
                md: isRight ? Span.Seven : Span.Five,
              }}
              className={gridColTwoClass}
            >
              {Content}
            </Col>
          </>
        ) : (
          Content
        )}
        {link && (
          <Col
            start={{
              default: Start.One,
              sm: Start.Three,
              md: isRight ? Start.Seven : Start.Two,
            }}
            span={{default: Span.Four}}
          >
            <Spacer size={isXSmall ? '3xl' : 'xl'} />
            {isButton && link?.url && (
              <Button
                type="link"
                href={link.url}
                variant={ButtonVariant.Secondary}
                theme={currentButtonTheme}
                componentName={link.name || ''}
              >
                {link.text}
              </Button>
            )}
            {!isButton && link?.url && (
              <Link
                href={link.url}
                theme={ButtonTheme.Dark}
                size={ButtonSizeEnum.Medium}
                componentName={link.name || ''}
              >
                {link.text}
              </Link>
            )}
          </Col>
        )}
      </Grid>
    </>
  );
}
