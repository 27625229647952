import type {SVGAttributes} from 'react';

const ApplePay = (props: SVGAttributes<SVGElement>) => (
  <svg width="58" height="37" viewBox="0 0 58 37" fill="none" {...props}>
    <rect
      x="0.5"
      y="0.5"
      width="57"
      height="36"
      rx="2.5"
      fill="white"
      stroke="#020202"
    />
    <path
      d="M7.52734 16.8708C7.54968 16.7076 7.57029 16.5427 7.59606 16.3795C7.71288 15.6785 7.9551 15.0257 8.37599 14.4485C8.8862 13.7511 9.56306 13.2958 10.3997 13.0759C11.0284 12.9093 11.64 12.9608 12.243 13.1859C12.5488 13.301 12.8528 13.4144 13.1586 13.5277C13.4352 13.6308 13.7118 13.6325 13.9884 13.5312C14.3182 13.4109 14.6463 13.2838 14.9779 13.1687C15.4623 13.0003 15.9588 12.8938 16.4759 12.9368C17.2764 13.0055 18.0014 13.2649 18.5889 13.8335C18.7607 14.0002 18.905 14.1943 19.0596 14.3712C18.8878 14.5069 18.7229 14.622 18.5752 14.7543C17.9309 15.3333 17.541 16.0445 17.4912 16.9206C17.4568 17.5064 17.5393 18.0733 17.8038 18.6059C18.1182 19.2346 18.6044 19.6916 19.2142 20.0266C19.2469 20.0437 19.2795 20.0626 19.3121 20.0781C19.453 20.1485 19.4513 20.1485 19.4049 20.2928C19.2572 20.7481 19.0596 21.1827 18.8243 21.5984C18.5116 22.1482 18.1697 22.6807 17.7557 23.1617C17.5496 23.4022 17.3211 23.6152 17.0479 23.7802C16.5944 24.0533 16.11 24.0997 15.6083 23.9691C15.385 23.9107 15.1686 23.8248 14.9573 23.7303C14.1481 23.3662 13.3459 23.3782 12.5402 23.7372C12.2567 23.8643 11.9681 23.9829 11.6589 24.0413C11.1882 24.1289 10.7707 24.019 10.3859 23.7424C9.9994 23.4641 9.69877 23.105 9.41703 22.7271C8.96694 22.1224 8.56496 21.4885 8.26604 20.7927C7.90528 19.9407 7.65619 19.0559 7.55998 18.1283C7.54968 18.0355 7.53765 17.9427 7.52734 17.85C7.52734 17.5236 7.52734 17.1972 7.52734 16.8708Z"
      fill="black"
    />
    <path
      d="M16.4254 9.36353C16.4288 9.48378 16.4357 9.60403 16.4323 9.72257C16.4151 10.4149 16.1626 11.0282 15.764 11.5813C15.3603 12.1448 14.8243 12.5245 14.1423 12.6859C13.9173 12.7392 13.6905 12.7426 13.4569 12.7186C13.4122 12.4265 13.4345 12.1414 13.5119 11.8648C13.8881 10.5369 14.759 9.72428 16.0956 9.40476C16.1694 9.38758 16.2468 9.37727 16.3223 9.36353C16.3567 9.36353 16.3911 9.36353 16.4254 9.36353Z"
      fill="black"
    />
    <path
      d="M23.9501 19.2896C23.9501 20.8288 23.9501 22.3543 23.9501 23.8867C23.2492 23.8867 22.5517 23.8867 21.8491 23.8867C21.8491 19.3944 21.8491 14.9003 21.8491 10.396C21.8818 10.396 21.9127 10.396 21.9419 10.396C23.5876 10.396 25.2351 10.396 26.8809 10.396C27.6951 10.396 28.4733 10.5506 29.1949 10.9406C30.0091 11.3804 30.6035 12.0263 30.9763 12.8715C31.3062 13.6171 31.4127 14.4021 31.3577 15.2113C31.3079 15.9517 31.1086 16.6457 30.7169 17.2796C30.2187 18.0888 29.5127 18.6454 28.6262 18.9803C28.0387 19.202 27.4254 19.2878 26.8001 19.2896C25.8862 19.2913 24.974 19.2896 24.0601 19.2896C24.0274 19.2896 23.9931 19.2896 23.9501 19.2896ZM23.9175 17.5407C23.945 17.5407 23.9673 17.5407 23.9896 17.5407C24.7764 17.5407 25.5632 17.5425 26.3517 17.539C26.685 17.5373 27.0148 17.5047 27.3378 17.4188C28.1727 17.1989 28.7671 16.7076 29.0454 15.8744C29.2275 15.3298 29.2361 14.7715 29.1365 14.2115C28.9836 13.3491 28.5094 12.7358 27.6865 12.4094C27.2433 12.2341 26.7778 12.186 26.3054 12.1843C25.5409 12.1826 24.7764 12.1843 24.012 12.1843C23.981 12.1843 23.9518 12.1843 23.9175 12.1843C23.9175 13.9727 23.9175 15.749 23.9175 17.5407Z"
      fill="black"
    />
    <path
      d="M32.3401 16.9979C32.3487 16.711 32.4036 16.4378 32.4998 16.1733C32.8177 15.2851 33.4447 14.6907 34.2899 14.3111C34.8499 14.0603 35.4409 13.9434 36.0525 13.9091C36.774 13.8696 37.4835 13.9331 38.1672 14.1719C39.0536 14.4794 39.7339 15.0223 40.1016 15.9104C40.2699 16.3159 40.3335 16.7419 40.3335 17.1765C40.3369 19.4012 40.3352 21.6259 40.3352 23.8489C40.3352 23.8712 40.3352 23.8935 40.3352 23.9227C39.6927 23.9227 39.0571 23.9227 38.4043 23.9227C38.4043 23.3902 38.4043 22.8559 38.4043 22.3216C38.3974 22.3199 38.3905 22.3199 38.3837 22.3182C38.3733 22.3302 38.3596 22.3405 38.3527 22.3543C37.8889 23.1875 37.1777 23.7114 36.2706 23.9588C35.2038 24.2491 34.1714 24.1409 33.2196 23.562C32.4689 23.105 32.0463 22.4161 31.9364 21.5417C31.8711 21.0298 31.914 20.5316 32.1202 20.054C32.3555 19.5094 32.7524 19.1143 33.2609 18.8257C33.7986 18.5199 34.381 18.3447 34.9925 18.2846C35.6728 18.2176 36.3565 18.1867 37.0385 18.1437C37.444 18.1179 37.8494 18.099 38.2565 18.075C38.2668 18.075 38.2789 18.0716 38.2995 18.0681C38.2995 17.9925 38.3012 17.9187 38.2995 17.8465C38.2892 17.5493 38.3064 17.2487 38.26 16.9566C38.1517 16.2592 37.7188 15.84 37.0488 15.6648C36.4991 15.5205 35.946 15.5394 35.4083 15.7249C34.8242 15.9259 34.4376 16.3227 34.2847 16.9309C34.2727 16.979 34.2607 17.0099 34.1989 17.0099C33.601 17.0065 33.0015 17.0082 32.4036 17.0082C32.3847 17.0047 32.3659 17.0013 32.3401 16.9979ZM38.2995 19.4682C38.2617 19.4682 38.2394 19.4665 38.217 19.4682C37.389 19.518 36.561 19.5644 35.7329 19.6228C35.3567 19.6486 34.9942 19.7379 34.6592 19.9235C33.9738 20.3031 33.8432 21.0349 34.0958 21.6225C34.283 22.0605 34.6489 22.301 35.0939 22.4144C35.7965 22.5931 36.4768 22.5072 37.1141 22.1653C37.736 21.832 38.1432 21.3235 38.2634 20.6192C38.3064 20.3701 38.2909 20.1124 38.2995 19.8582C38.3029 19.7311 38.2995 19.6022 38.2995 19.4682Z"
      fill="black"
    />
    <path
      d="M42.1285 27.5734C42.1285 27.0237 42.1285 26.4843 42.1285 25.9586C42.3896 25.9586 42.6473 25.9655 42.9067 25.9569C43.2881 25.9448 43.642 25.8486 43.9323 25.5841C44.1453 25.3917 44.293 25.1529 44.3927 24.8883C44.5009 24.5963 44.5885 24.2974 44.6796 24.0002C44.6899 23.9658 44.6761 23.9212 44.6641 23.8851C44.1195 22.3802 43.575 20.8753 43.0287 19.3721C42.4034 17.6474 41.778 15.9226 41.1527 14.1978C41.1441 14.1738 41.1373 14.1497 41.127 14.1171C41.163 14.1154 41.194 14.1137 41.2232 14.1137C41.8845 14.1137 42.5459 14.1154 43.2073 14.1119C43.276 14.1119 43.307 14.1308 43.3276 14.1978C44.1401 16.8176 44.9562 19.4374 45.7704 22.0572C45.7807 22.0882 45.7928 22.1174 45.8031 22.1483C45.8117 22.1483 45.8203 22.1483 45.8271 22.1483C45.8374 22.1208 45.8495 22.095 45.858 22.0675C46.4868 20.049 47.1156 18.0305 47.7443 16.0102C47.9333 15.4038 48.1222 14.7991 48.3095 14.1927C48.325 14.1411 48.3404 14.1119 48.4057 14.1119C49.0705 14.1154 49.7336 14.1137 50.3985 14.1137C50.4208 14.1137 50.4431 14.1171 50.474 14.1205C50.3933 14.3473 50.316 14.5672 50.2387 14.7871C49.0929 18.0219 47.9487 21.2567 46.8029 24.4915C46.6053 25.0498 46.3769 25.5961 46.0608 26.0994C45.7584 26.5787 45.3891 26.9893 44.8771 27.2539C44.494 27.4532 44.0835 27.5614 43.6557 27.6026C43.4427 27.6232 43.2262 27.6421 43.0132 27.6353C42.7177 27.6301 42.4257 27.5957 42.1285 27.5734Z"
      fill="black"
    />
  </svg>
);

export default ApplePay;
