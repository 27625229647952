import AutoGrid from '@/components/brochureV2/layout/AutoGrid/AutoGrid';
import Col, {Span, Start} from '@/components/brochureV2/layout/Grid/Col';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Block from '@/components/brochureV2/primitive/Block/Block';
import Button from '@/components/brochureV2/primitive/Button/Button';
import {ButtonTheme} from '@/components/brochureV2/primitive/Button/types';
import Link from '@/components/brochureV2/primitive/Link/Link';
import Image from '@/components/shared/Image/Image';
import {AlignmentEnum, ButtonSizeEnum} from '@/enums';
import type {BlockComponent, ImageComponent, LinkComponent} from '@/types';

import {Heading} from '../shared';

interface Outcomes03Props {
  alignment?: AlignmentEnum;
  blocks?: BlockComponent[];
  heading?: string;
  image?: ImageComponent;
  kicker?: string;
  link?: LinkComponent;
  subhead?: string;
  buttonTheme?: ButtonTheme;
  eyebrowClass?: string;
  linkTheme?: ButtonTheme;
}

/**
 * @deprecated
 */
export default function Outcomes03({
  alignment,
  blocks,
  buttonTheme,
  eyebrowClass,
  heading,
  image,
  kicker,
  link,
  linkTheme,
  subhead,
}: Outcomes03Props) {
  // component defaults to right & doesn't support center
  const currentAlignment = alignment || AlignmentEnum.Right;
  const isLeft = currentAlignment === AlignmentEnum.Left;
  const currentButtonTheme = buttonTheme || ButtonTheme.Light;
  const currentLinkTheme = linkTheme || ButtonTheme.Dark;
  const isButton = link?.name === 'button';
  const headingProps = {kicker, heading, subhead, eyebrowClass};
  const hasHeading = Boolean(kicker) || Boolean(heading) || Boolean(subhead);
  return (
    <>
      {hasHeading && (
        <div className="container">
          <Heading {...headingProps} />
          <Spacer size="3xl" />
        </div>
      )}
      <Grid className="items-center">
        <Col
          className="md:row-span-full self-start"
          start={{
            default: Start.One,
            md: isLeft ? Start.Eight : Start.One,
          }}
          span={{default: Span.Four, sm: Span.Five}}
        >
          {image?.srcSet && (
            <Image
              className="mb-3xl md:mb-0"
              sizes={image.sizes}
              srcSet={image.srcSet}
              alt={image.alt}
              loading="lazy"
            />
          )}
        </Col>
        <Col
          className="md:row-span-full "
          start={{
            default: Start.One,
            md: isLeft ? Start.One : Start.Seven,
          }}
          span={{default: Span.Four, sm: Span.Eight, md: Span.Six}}
        >
          {blocks && blocks.length > 0 && (
            <AutoGrid
              cellClass="sm:basis-1/2-gutter"
              className="mx-0 md:mx-0 lg:mx-0"
              threshold={2}
            >
              {blocks?.map((block, index) => (
                <AutoGrid.Cell
                  key={block.heading}
                  className={`mb-6 last:mb-0 md:last:mb-0${
                    index === blocks?.length - 2 ? 'md:mb-0' : 'md:mb-16'
                  }`}
                >
                  <Block {...block} linkTheme={currentLinkTheme} size="small" />
                  {index < blocks?.length - 1 && <Spacer size="xl" />}
                </AutoGrid.Cell>
              ))}
            </AutoGrid>
          )}
          {link && (
            <AutoGrid className="mx-0 md:mx-0 lg:mx-0">
              <AutoGrid.Cell className="md:row-span-full ">
                <Spacer size="xl" />
                {isButton && link.url && (
                  <Button
                    type="link"
                    theme={currentButtonTheme}
                    href={link.url}
                    componentName={link.name || ''}
                  >
                    {link.text}
                  </Button>
                )}
                {!isButton && link?.url && (
                  <Link
                    href={link.url}
                    size={ButtonSizeEnum.Medium}
                    theme={currentLinkTheme}
                    componentName={link.name || ''}
                  >
                    {link.text}
                  </Link>
                )}
              </AutoGrid.Cell>
            </AutoGrid>
          )}
        </Col>
      </Grid>
    </>
  );
}
