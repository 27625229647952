import cn from 'classnames';
import {Fragment} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import AutoGrid from '@/components/brochureV2/layout/AutoGrid/AutoGrid';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Block from '@/components/brochureV2/primitive/Block/Block';
import type {BlockProps} from '@/components/brochureV2/primitive/Block/types';
import DefaultButton from '@/components/brochureV2/primitive/Button/Button';
import {
  ButtonTheme,
  ButtonVariant,
  type ButtonProps,
} from '@/components/brochureV2/primitive/Button/types';
import Image from '@/components/shared/Image/Image';
import type {BlockComponent, LinkComponent} from '@/types';

import {Heading} from '../shared';

export interface Outcomes01Props {
  blocks?: BlockComponent[];
  heading?: string;
  kicker?: string;
  link?: LinkComponent;
  subhead?: string;
  blocksClass?: string;
  blockContainerClass?: string;
  blockSize?: BlockProps['size'];
  headingClass?: string;
  showUnderHeadingSpacer?: boolean;
  eyebrowClass?: string;
  buttonTheme?: ButtonTheme;
  customButton?: (props: ButtonProps) => JSX.Element;
}

/**
 * @deprecated
 */
export default function Outcomes01({
  blocks,
  blocksClass,
  blockContainerClass,
  blockSize = 'small',
  buttonTheme = ButtonTheme.Light,
  eyebrowClass,
  heading,
  headingClass,
  showUnderHeadingSpacer = true,
  kicker,
  link: button,
  subhead,
  customButton,
}: Outcomes01Props) {
  const Button = customButton ?? DefaultButton;

  const headingProps = {kicker, heading, headingClass, subhead, eyebrowClass};
  const hasHeading = Boolean(kicker) || Boolean(heading) || Boolean(subhead);
  return (
    <div className="container">
      <div className="sm:px-gutter sm:mx-[12.5%] md:mx-0 md:px-0">
        {hasHeading ? (
          <>
            <Heading {...headingProps} />
            {showUnderHeadingSpacer && <Spacer size="4xl" />}
          </>
        ) : null}
        {blocks && (
          <AutoGrid
            className="gap-y-2xl"
            cellClass={blocksClass}
            threshold={blocks.length === 4 ? 4 : 3}
          >
            {blocks?.map((block, index) => {
              const icon = block.icon;
              const hasIcon = Boolean(icon?.src);
              const loopKey = `block-${index.toString()}`;
              return (
                <Fragment key={loopKey}>
                  {icon?.src && (
                    <Image
                      src={icon.src}
                      alt={icon.alt}
                      loading="lazy"
                      className="block h-16 w-16 rounded-md object-contain"
                    />
                  )}
                  <AutoGrid.Cell
                    key={heading}
                    className={twMerge(
                      cn({'border-t-2': !hasIcon}),
                      blockContainerClass,
                    )}
                  >
                    {hasIcon ? <Spacer size="sm" /> : <Spacer size="md" />}
                    <Block
                      key={block.heading}
                      {...block}
                      linkTheme={buttonTheme}
                      size={blockSize}
                    />
                  </AutoGrid.Cell>
                </Fragment>
              );
            })}
          </AutoGrid>
        )}
        {button?.url && (
          <>
            <Spacer size="3xl" />
            <div className="flex justify-center">
              <Button
                type="link"
                href={button.url}
                variant={ButtonVariant.Secondary}
                theme={buttonTheme}
                componentName={button.name || ''}
              >
                {button.text}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
