import type {SVGAttributes} from 'react';

const ArrowLeft = (props: SVGAttributes<SVGElement>) => (
  <svg width="58" height="37" viewBox="0 0 58 37" fill="none" {...props}>
    <rect
      x="0.5"
      y="0.5"
      width="57"
      height="36"
      rx="2.5"
      fill="white"
      stroke="#5F6368"
    />
    <path
      d="M27.7366 18.2879V23.7187H26.0137V10.3069H30.5831C31.1285 10.2955 31.6708 10.3923 32.1786 10.5916C32.6865 10.7908 33.1498 11.0887 33.542 11.468C33.938 11.8243 34.2533 12.2612 34.4665 12.7494C34.6796 13.2376 34.7859 13.7658 34.778 14.2985C34.7893 14.834 34.6847 15.3656 34.4714 15.857C34.258 16.3483 33.941 16.7877 33.542 17.145C32.7429 17.9069 31.7566 18.2875 30.5831 18.2868H27.7366V18.2879ZM27.7366 11.9581V16.6399H30.6259C30.9426 16.6493 31.2579 16.5925 31.5514 16.4731C31.8449 16.3537 32.1103 16.1743 32.3306 15.9465C32.5497 15.7334 32.7239 15.4786 32.8428 15.1972C32.9617 14.9157 33.023 14.6132 33.023 14.3076C33.023 14.002 32.9617 13.6995 32.8428 13.418C32.7239 13.1365 32.5497 12.8817 32.3306 12.6687C32.1131 12.436 31.8486 12.2523 31.5547 12.1296C31.2608 12.0069 30.9442 11.9481 30.6259 11.9571H27.7366V11.9581Z"
      fill="#5F6368"
    />
    <path
      d="M38.7488 14.2427C40.0222 14.2427 41.0274 14.583 41.7644 15.2636C42.5013 15.9442 42.8695 16.8773 42.8688 18.063V23.7186H41.2208V22.4451H41.1459C40.4324 23.4939 39.4836 24.0182 38.2993 24.0182C37.2884 24.0182 36.4427 23.7186 35.7621 23.1193C35.4355 22.8443 35.1743 22.4998 34.9977 22.111C34.8211 21.7223 34.7335 21.299 34.7412 20.8721C34.7412 19.9225 35.1 19.1674 35.8177 18.6066C36.5354 18.0459 37.4935 17.7648 38.6921 17.7634C39.7151 17.7634 40.5577 17.9506 41.2197 18.3252V17.9314C41.2216 17.6403 41.1588 17.3525 41.0357 17.0887C40.9126 16.825 40.7324 16.5919 40.5081 16.4065C40.0525 15.9954 39.4587 15.7711 38.8451 15.7783C37.8827 15.7783 37.1211 16.1842 36.5604 16.9961L35.043 16.0405C35.8777 14.8419 37.1129 14.2427 38.7488 14.2427ZM36.5197 20.9095C36.5186 21.1288 36.5698 21.3452 36.6692 21.5406C36.7686 21.7361 36.9133 21.9049 37.0912 22.0331C37.4722 22.3329 37.9452 22.4917 38.4299 22.4826C39.1569 22.4814 39.8538 22.192 40.3679 21.6779C40.9386 21.1407 41.224 20.5104 41.224 19.787C40.6868 19.3589 39.9377 19.1449 38.9767 19.1449C38.2769 19.1449 37.6933 19.3136 37.226 19.6511C36.7541 19.9935 36.5197 20.4098 36.5197 20.9095Z"
      fill="#5F6368"
    />
    <path
      d="M52.3284 14.5425L46.5754 27.7638H44.7969L46.9318 23.1377L43.1489 14.5425H45.0216L47.7558 21.1344H47.7932L50.4525 14.5425H52.3284Z"
      fill="#5F6368"
    />
    <path
      d="M20.7743 17.1172C20.7749 16.5921 20.7306 16.068 20.6416 15.5505H13.3755V18.518H17.5372C17.452 18.9919 17.2718 19.4437 17.0073 19.8461C16.7428 20.2485 16.3996 20.5932 15.9984 20.8594V22.7856H18.4821C19.9364 21.4448 20.7743 19.4618 20.7743 17.1172Z"
      fill="#4285F4"
    />
    <path
      d="M13.3768 24.6465C15.456 24.6465 17.2067 23.9638 18.4834 22.7866L15.9996 20.8604C15.3083 21.3291 14.418 21.5967 13.3768 21.5967C11.3671 21.5967 9.6613 20.2419 9.05133 18.4163H6.49268V20.4013C7.13396 21.6774 8.11731 22.7502 9.33292 23.4998C10.5485 24.2494 11.9486 24.6464 13.3768 24.6465Z"
      fill="#34A853"
    />
    <path
      d="M9.05096 18.4161C8.72849 17.4595 8.72849 16.4235 9.05096 15.4669V13.4818H6.49231C5.95283 14.5553 5.67187 15.7401 5.67188 16.9415C5.67187 18.1429 5.95283 19.3277 6.49231 20.4012L9.05096 18.4161Z"
      fill="#FBBC04"
    />
    <path
      d="M13.3768 12.2867C14.4755 12.2687 15.5373 12.6839 16.3324 13.4424L18.5315 11.2433C17.1371 9.93356 15.2897 9.21449 13.3768 9.23683C11.9486 9.2369 10.5485 9.63391 9.33292 10.3835C8.11731 11.1332 7.13396 12.2059 6.49268 13.482L9.05133 15.4671C9.6613 13.6414 11.3671 12.2867 13.3768 12.2867Z"
      fill="#EA4335"
    />
  </svg>
);

export default ArrowLeft;
