import type {VariantMap} from '@/types';

import Outcomes01 from './Outcomes01';
import Outcomes01PaymentIcons from './Outcomes01PaymentIcons';
import Outcomes02 from './Outcomes02';
import Outcomes02LargeIconsList from './Outcomes02LargeIconsList';
import Outcomes02PreOrder from './Outcomes02PreOrder';
import Outcomes03 from './Outcomes03';

export enum OutcomesVariant {
  IconsList = 'icons_list',
  PaymentIcons = 'payment_icons',
  Preorder = 'preorder',
  V01 = 'v01',
  V02 = 'v02',
  V03 = 'v03',
}

const variants: VariantMap = {
  [OutcomesVariant.IconsList]: Outcomes02LargeIconsList,
  [OutcomesVariant.PaymentIcons]: Outcomes01PaymentIcons,
  [OutcomesVariant.Preorder]: Outcomes02PreOrder,
  [OutcomesVariant.V01]: Outcomes01,
  [OutcomesVariant.V02]: Outcomes02,
  [OutcomesVariant.V03]: Outcomes03,
};

export default variants;
