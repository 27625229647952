import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import Col, {Span, Start} from '@/components/brochureV2/layout/Grid/Col';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Block from '@/components/brochureV2/primitive/Block/Block';
import DefaultButton from '@/components/brochureV2/primitive/Button/Button';
import {
  ButtonTheme,
  ButtonVariant,
  type ButtonProps,
} from '@/components/brochureV2/primitive/Button/types';
import Link from '@/components/brochureV2/primitive/Link/Link';
import Image from '@/components/shared/Image/Image';
import WistiaVideoPlayer from '@/components/shared/WistiaVideoPlayer';
import {AlignmentEnum, ButtonSizeEnum} from '@/enums';
import type {
  BlockComponent,
  ImageComponent,
  LinkComponent,
  VideoComponent,
} from '@/types';

import {Heading} from '../shared';

interface Outcomes02Props {
  alignment?: AlignmentEnum;
  blocks?: BlockComponent[];
  heading?: string;
  image?: ImageComponent;
  kicker?: string;
  link?: LinkComponent;
  linkImage?: ImageComponent;
  subhead?: string;
  video?: VideoComponent;
  buttonTheme?: ButtonTheme;
  h3CustomSize?: string;
  headingClass?: string;
  eyebrowClass?: string;
  gridColOneClass?: string;
  gridColTwoClass?: string;
  customButton?: (props: ButtonProps) => JSX.Element;
}

type ButtonAlignment = AlignmentEnum.Right | AlignmentEnum.Left;

/**
 * @deprecated
 */
// eslint-disable-next-line complexity
export default function Outcomes02({
  alignment,
  blocks,
  buttonTheme = ButtonTheme.Dark,
  eyebrowClass,
  h3CustomSize = 'small',
  heading,
  headingClass,
  gridColOneClass,
  gridColTwoClass,
  image,
  kicker,
  link,
  linkImage,
  subhead,
  video,
  customButton,
}: Outcomes02Props) {
  // component defaults to right & doesn't support center
  const currentAlignment = alignment || AlignmentEnum.Right;
  const isLeft = currentAlignment === AlignmentEnum.Left;
  const isButton = link?.name === 'button';
  const Button = customButton ?? DefaultButton;
  const headingProps = {kicker, heading, subhead, eyebrowClass, headingClass};
  const hasHeading = Boolean(kicker) || Boolean(heading) || Boolean(subhead);
  const buttonStartWithIcon = {
    right: {
      default: Start.Two,
      sm: Start.Four,
      md: Start.Seven,
      xl: Start.Eight,
    },
    left: {
      default: Start.Two,
      sm: Start.Two,
      md: Start.Two,
      xl: Start.Two,
    },
  };
  const buttonStartWithoutIcon = {
    right: {
      default: Start.One,
      sm: Start.One,
      md: Start.Six,
      xl: Start.Seven,
    },
    left: {
      default: Start.One,
      sm: Start.One,
      md: Start.One,
      xl: Start.One,
    },
  };
  const firstIcon = blocks && blocks[0]?.icon;
  const key = currentAlignment as ButtonAlignment;
  const buttonStart = (size: 'default' | 'sm' | 'md' | 'xl') =>
    firstIcon
      ? buttonStartWithIcon[key][size]
      : buttonStartWithoutIcon[key][size];
  const isButtonPositionRight = video?.buttonPosition === AlignmentEnum.Right;
  const Content = blocks?.map((block, index, arr) => [
    <Col
      key={`${block.heading}-icon`}
      start={{
        default: Start.One,
        sm: isLeft ? Start.One : Start.Three,
        md: isLeft ? Start.One : Start.Six,
      }}
      span={Span.One}
    >
      {block.icon?.src && (
        <Image
          alt={block.icon.alt}
          className="mb-2 p-1 sm:mb-0"
          src={block.icon.src}
          width="64"
          loading="lazy"
        />
      )}
    </Col>,
    <Col
      key={`${block.heading}-content`}
      start={{
        default: Start.Two,
        sm: isLeft ? Start.Two : Start.Four,
        md: isLeft ? Start.Two : Start.Seven,
      }}
      span={{default: Span.Three, sm: Span.Five, md: Span.Six}}
    >
      <Block
        key={`${block.heading}-block`}
        {...block}
        linkTheme={buttonTheme}
        size={h3CustomSize}
      />
      {(index + 1 < arr.length || link?.text) && <Spacer size="xl" />}
    </Col>,
  ]);

  return (
    <>
      {hasHeading ? (
        <div className="container">
          <Heading {...headingProps} />
          <Spacer size="3xl" />
        </div>
      ) : null}
      <Grid>
        {image || video?.videoId ? (
          <>
            <Col
              start={{
                default: Start.One,
                md: isLeft ? Start.Seven : Start.One,
              }}
              span={{default: Span.Twelve, md: Span.Five}}
              className={twMerge('row-span-full', gridColOneClass)}
            >
              {video?.videoId ? (
                <div className="mb-3xl md:mb-0">
                  <WistiaVideoPlayer
                    {...video}
                    videoId={video?.videoId}
                    autoPlay
                    disableBottomBar
                    buttonPosition={video?.buttonPosition || AlignmentEnum.Left}
                    classNames={{
                      videoWrapper: 'aspect-square',
                      playButton: isButtonPositionRight ? 'right-1' : undefined,
                    }}
                  />
                </div>
              ) : (
                image?.srcSet && (
                  <Image
                    className="mb-3xl md:mb-0"
                    sizes={image.sizes}
                    srcSet={image.srcSet}
                    alt={image.alt}
                    loading="lazy"
                  />
                )
              )}
            </Col>
            <Col
              span={{
                default: Span.Twelve,
                md: isLeft ? Span.Six : Span.Seven,
              }}
              start={{
                default: Start.One,
                md: isLeft ? Start.One : Start.Seven,
              }}
              className={twMerge(
                cn({
                  'self-center': !linkImage,
                  'md:row-span-full': linkImage,
                }),
                gridColTwoClass,
              )}
            >
              {/* This change will keep the block content left alignment */}
              <div
                className={cn('container ml-0', {
                  'md:h-full md:flex md:flex-col': linkImage,
                })}
              >
                {linkImage?.srcSet && <div className="mt-auto" />}
                {Content}
                {linkImage?.srcSet && (
                  <div className="mt-auto">
                    <Image
                      sizes={linkImage.sizes}
                      srcSet={linkImage.srcSet}
                      alt={linkImage.alt}
                      loading="lazy"
                    />
                  </div>
                )}
                {link && (
                  <Col
                    start={{
                      default: buttonStart('default'),
                      sm: buttonStart('sm'),
                      md: buttonStart('md'),
                      xl: firstIcon ? buttonStart('md') : buttonStart('xl'),
                    }}
                    span={{default: Span.Four, sm: Span.Five, md: Span.Six}}
                  >
                    <div
                      className={cn({
                        'md:mt-3': linkImage,
                      })}
                    >
                      {isButton && link.url && (
                        <Button
                          type="link"
                          href={link.url}
                          variant={ButtonVariant.Secondary}
                          theme={buttonTheme}
                          componentName={link.name || ''}
                        >
                          {link.text}
                        </Button>
                      )}
                      {!isButton && link.url && (
                        <Link
                          href={link.url}
                          theme={buttonTheme}
                          size={ButtonSizeEnum.Medium}
                          componentName={link.name || ''}
                        >
                          {link.text}
                        </Link>
                      )}
                    </div>
                  </Col>
                )}
              </div>
            </Col>
          </>
        ) : (
          Content
        )}
      </Grid>
    </>
  );
}
