import type {SVGAttributes} from 'react';

const Visa = (props: SVGAttributes<SVGElement>) => (
  <svg width="58" height="37" viewBox="0 0 58 37" fill="none" {...props}>
    <path
      d="M55.0931 36.7287H2.90692C1.29197 36.7287 0 35.4368 0 33.8218V2.90692C0 1.29197 1.29197 0 2.90692 0H55.0931C56.708 0 58 1.29197 58 2.90692V33.8218C58 35.4368 56.6619 36.7287 55.0931 36.7287Z"
      fill="#1A1F70"
    />
    <path
      d="M28.839 12.7814L26.4396 23.9476H23.5327L25.9321 12.7814H28.839ZM40.9742 20.0256L42.4969 15.8267L43.3736 20.0256H40.9742ZM44.2041 23.9476H46.8804L44.5271 12.7814H42.0816C41.5279 12.7814 41.0665 13.1044 40.8358 13.6119L36.4985 23.9476H39.5438L40.1437 22.2865H43.835L44.2041 23.9476ZM36.6831 20.3025C36.6831 17.3494 32.6226 17.211 32.6226 15.8729C32.6226 15.4576 32.9917 15.0423 33.8684 14.95C34.2837 14.9039 35.4372 14.8577 36.7292 15.4576L37.2368 13.1044C36.5446 12.8737 35.6679 12.5968 34.5144 12.5968C31.6536 12.5968 29.6695 14.1195 29.6234 16.2881C29.6234 17.9031 31.0538 18.7798 32.1612 19.3335C33.2686 19.8872 33.6839 20.2102 33.6377 20.7177C33.6377 21.456 32.761 21.779 31.9305 21.8251C30.4539 21.8251 29.6234 21.4098 28.9774 21.133L28.4237 23.5324C29.1158 23.8554 30.3617 24.1322 31.6536 24.1322C34.6528 24.1322 36.6369 22.6557 36.6831 20.3025ZM24.6862 12.7814L20.0259 23.9476H16.9806L14.6735 15.0423C14.5351 14.4886 14.3967 14.304 13.9814 14.0733C13.2893 13.7042 12.1357 13.3351 11.1206 13.1044L11.2129 12.7814H16.15C16.796 12.7814 17.3497 13.1966 17.4881 13.9349L18.6878 20.3947L21.687 12.7814H24.6862Z"
      fill="white"
    />
  </svg>
);

export default Visa;
