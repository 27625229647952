import type {SVGAttributes} from 'react';

const ShopPay = (props: SVGAttributes<SVGElement>) => (
  <svg width="58" height="37" viewBox="0 0 58 37" fill="none" {...props}>
    <g clipPath="url(#clip0_2889_67544)">
      <path
        d="M53.413 0.20874H4.32067C2.07681 0.20874 0.257812 2.02774 0.257812 4.27159V32.7947C0.257812 35.0385 2.07681 36.8575 4.32067 36.8575H53.413C55.6571 36.8575 57.4761 35.0385 57.4761 32.7947V4.27159C57.4761 2.02774 55.6571 0.20874 53.413 0.20874Z"
        fill="#5A31F4"
      />
      <path
        d="M12.3516 9.57871C10.184 9.56331 8.09287 10.379 6.50824 11.8581C6.44019 11.9203 6.39775 12.0055 6.38927 12.0973C6.38078 12.189 6.40686 12.2806 6.46236 12.3542L7.44297 13.6932C7.47477 13.7378 7.51593 13.775 7.56362 13.802C7.6113 13.8291 7.66434 13.8453 7.71899 13.8497C7.77364 13.8541 7.82857 13.8465 7.87995 13.8273C7.93134 13.8082 7.97791 13.7781 8.01641 13.739C8.58616 13.1694 9.26375 12.719 10.0096 12.4142C10.7554 12.1095 11.5546 11.9565 12.3602 11.9642C15.7235 11.9642 17.4811 14.496 17.4811 16.9962C17.4811 19.7143 15.6346 21.6268 12.9824 21.664C10.9324 21.664 9.38694 20.305 9.38694 18.5101C9.38896 18.0566 9.49158 17.6092 9.68742 17.2001C9.88328 16.7911 10.1675 16.4306 10.5195 16.1446C10.594 16.0826 10.6412 15.9937 10.6509 15.8971C10.6606 15.8006 10.6319 15.7041 10.5711 15.6285L9.5389 14.3268C9.50772 14.2874 9.46894 14.2547 9.42485 14.2305C9.38078 14.2064 9.33232 14.1913 9.28233 14.1862C9.23234 14.1811 9.18181 14.1861 9.13379 14.2009C9.08577 14.2157 9.04118 14.24 9.00271 14.2723C8.36954 14.7824 7.85861 15.4278 7.50734 16.1611C7.15607 16.8944 6.9734 17.697 6.97273 18.5101C6.97273 21.6009 9.59911 24.0237 12.9624 24.0438H13.0082C17.0023 23.9922 19.8895 21.0332 19.8895 16.9905C19.8895 13.4007 17.246 9.57871 12.3516 9.57871Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9931 23.3061V18.3918H28.0867C30.913 18.3918 32.4162 16.8081 32.4162 14.4081C32.4162 12.0082 30.913 10.5714 28.0867 10.5714H23V23.3061H24.9931ZM24.9931 12.4653H27.6292C29.4591 12.4653 30.3575 13.2163 30.3575 14.4734C30.3575 15.7306 29.4917 16.4816 27.7109 16.4816H24.9931V12.4653ZM35.9301 23.5673C37.4494 23.5673 38.446 22.898 38.9035 21.7551C39.0341 23.0285 39.802 23.6816 41.4685 23.2408L41.4848 21.8857C40.8149 21.9509 40.6843 21.706 40.6843 21.004V17.6734C40.6843 15.7143 39.3936 14.5551 37.0082 14.5551C34.6557 14.5551 33.2997 15.7306 33.2997 17.7224H35.1294C35.1294 16.7755 35.7994 16.204 36.9756 16.204C38.2173 16.204 38.789 16.7428 38.7727 17.6734V18.0979L36.6652 18.3265C34.2964 18.5877 32.9893 19.4857 32.9893 21.053C32.9893 22.3427 33.9043 23.5673 35.9301 23.5673ZM36.3385 22.0979C35.3093 22.0979 34.9007 21.5428 34.9007 20.9877C34.9007 20.2367 35.7503 19.8938 37.4168 19.6979L38.7238 19.551C38.642 20.9877 37.6781 22.0979 36.3385 22.0979ZM47.5365 23.9428C46.7033 25.9673 45.3636 26.5714 43.2724 26.5714H42.3737V24.906H43.3378C44.4815 24.906 45.0369 24.5469 45.6413 23.5184L41.9328 14.8163H43.9912L46.6379 21.1672L48.9905 14.8163H51L47.5365 23.9428Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2889_67544">
        <rect width="57.6993" height="37" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ShopPay;
