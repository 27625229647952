import Eyebrow from '@/components/brochureV2/primitive/Eyebrow/Eyebrow';
import {H2Html} from '@/components/shared/HtmlElements';
import {twMerge} from '@/stylesheets/twMerge';

interface HeadingProps {
  heading?: string;
  kicker?: string;
  subhead?: string;
  headingClass?: string;
  eyebrowClass?: string;
}

/**
 * @deprecated
 */
export default function Heading({
  heading,
  headingClass,
  eyebrowClass,
  kicker,
  subhead,
}: HeadingProps) {
  if (!kicker && !heading && !subhead) {
    return null;
  }
  return (
    <div className="md:w-3/4">
      {kicker && (
        <Eyebrow spacerSize="sm" className={eyebrowClass}>
          {kicker}
        </Eyebrow>
      )}
      {heading && (
        <H2Html className={twMerge('font-bold text-t2', headingClass)}>
          {heading}
        </H2Html>
      )}
      {subhead && <p className="mt-md text-body-lg font-normal">{subhead}</p>}
    </div>
  );
}
