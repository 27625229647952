import Block from '@/components/brochureV2/primitive/Block/Block';
import Grid from '@/components/brochureV2/layout/Grid/Grid';
import Col, {Start, Span} from '@/components/brochureV2/layout/Grid/Col';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import {ButtonTheme} from '@/components/brochureV2/primitive/Button/types';
import {AlignmentEnum} from '@/enums';
import type {BlockComponent, LinkComponent} from '@/types';

import {Heading} from '../shared';

interface Outcomes02Props {
  alignment?: AlignmentEnum;
  blocks?: BlockComponent[];
  heading?: string;
  kicker?: string;
  link?: LinkComponent;
  subhead?: string;
  buttonTheme?: ButtonTheme;
  eyebrowClass?: string;
}

/**
 * @deprecated
 */
export default function Outcomes02LargeIconsList({
  alignment,
  blocks,
  buttonTheme = ButtonTheme.Light,
  eyebrowClass,
  heading,
  kicker,
  link,
  subhead,
}: Outcomes02Props) {
  // component defaults to left
  const currentAlignment: string = alignment || AlignmentEnum.Left;
  const isLeft = currentAlignment === AlignmentEnum.Left;
  const headingProps = {kicker, heading, subhead, eyebrowClass};
  const hasHeading = Boolean(kicker) || Boolean(heading) || Boolean(subhead);

  const defineGrid = (
    alignmentLeftStyles: Start,
    alignmentCenterStyles: Start,
    alignmentRightStyles: Start,
  ) => {
    return {
      [AlignmentEnum.Left]: alignmentLeftStyles,
      [AlignmentEnum.Center]: alignmentCenterStyles,
      [AlignmentEnum.Right]: alignmentRightStyles,
    }[currentAlignment];
  };

  const Content = blocks?.map((block, index, arr) => [
    <Col
      key={`${block.heading}-icon`}
      start={{
        default: Start.One,
        sm: defineGrid(Start.One, Start.Two, Start.Three),
        md: defineGrid(Start.Two, Start.Four, Start.Six),
      }}
      span={Span.Two}
      className="sm:flex sm:justify-center sm:items-start"
    >
      {block.icon?.src && (
        <img
          alt={block.icon.alt ?? ''}
          className="mb-2 p-1 sm:mb-0"
          src={block.icon.src}
          width="auto"
          loading="lazy"
        />
      )}
    </Col>,
    <Col
      key={`${block.heading}-content`}
      start={{
        default: Start.One,
        sm: defineGrid(Start.Three, Start.Four, Start.Five),
        md: defineGrid(Start.Four, Start.Six, Start.Eight),
      }}
      span={{
        default: Span.Four,
        sm: isLeft ? Span.Five : Span.Four,
        md: isLeft ? Span.Six : Span.Five,
      }}
    >
      <Block
        key={`${block.heading}-block`}
        {...block}
        linkTheme={buttonTheme}
        size="small"
      />
      {(index + 1 < arr.length || link?.text) && <Spacer size="3xl" />}
    </Col>,
  ]);

  return (
    <>
      {hasHeading ? (
        <div className="container">
          <Heading {...headingProps} />
          <Spacer size="3xl" />
        </div>
      ) : null}
      <Grid>{Content}</Grid>
    </>
  );
}
