import AmericanExpress from '@/components/brochureV2/icons/payment/AmericanExpress';
import ApplePay from '@/components/brochureV2/icons/payment/ApplePay';
import GooglePay from '@/components/brochureV2/icons/payment/GooglePay';
import Mastercard from '@/components/brochureV2/icons/payment/Mastercard';
import ShopPay from '@/components/brochureV2/icons/payment/ShopPay';
import Visa from '@/components/brochureV2/icons/payment/Visa';

import Outcomes01 from './Outcomes01';
import type {Outcomes01Props} from './Outcomes01';

/**
 * @deprecated
 */
export default function Outcomes01PaymentIcons(props: Outcomes01Props) {
  return (
    <>
      <Outcomes01 {...props} />
      <div className="container">
        <div className="sm:px-gutter sm:mx-[12.5%] md:mx-0 md:px-0">
          <div className="mt-16 flex">
            <Visa className="mr-2 md:mr-4" />
            <Mastercard className="mr-2 md:mr-4" />
            <AmericanExpress className="mr-2 md:mr-4" />
            <ApplePay className="mr-2 md:mr-4" />
            <GooglePay className="mr-2 md:mr-4" />
            <ShopPay />
          </div>
        </div>
      </div>
    </>
  );
}
